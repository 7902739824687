import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import {RotatingLines} from "react-loader-spinner";
import axios from "axios";
import "./css/global.css"



//ライブラリー
const { ethers } = require("ethers");
const { MerkleTree } = require( 'merkletreejs');
const keccak256 = require( 'keccak256');

//プロジェクトごとに設定
//abi,bytecodeを取り込む。srcにリンクを張る。
const {abi} = require("./MOONERC721A.json");
//Goerli
const contractAddress = "0xe026f06d4b5E522bc72879EF3DBf7A59E007Ef77"; //NFTのdeploy時のアドレスを指定する。
const checkChainId = 5;//対応するChainId.  5=Goerli 4=rinkeby。1=main polygon=137
const ipfsGateway = "https://cloudflare-ipfs.com/";//ipfs　gateway 

//local
//const contractAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3"; //NFTのdeploy時のアドレスを指定する。
//const checkChainId = 31337;//対応するChainId.  4=rinkeby。1=main polygon=137 local=31337


//定数
//const hash0 = ethers.constants.HashZero;

//walletの変更でイベント取りたい todo
//const providerFirst = new ethers.providers.Web3Provider(window.ethereum,"any");

//マークルツリーwhitelistを作成。
const whitelist = require("./whitelist");
const leafNodes = whitelist.map(one => ethers.utils.solidityKeccak256(['address', 'uint256'],[one[0], one[1]]));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
const rootHash = merkleTree.getRoot();//root
const whitelist1 = require("./whitelist1");
const leafNodes1 = whitelist1.map(one => ethers.utils.solidityKeccak256(['address', 'uint256'],[one[0], one[1]]));
const merkleTree1 = new MerkleTree(leafNodes1, keccak256, { sortPairs: true });
const rootHash1 = merkleTree1.getRoot();//root
const whitelist2 = require("./whitelist2");
const leafNodes2 = whitelist2.map(one => ethers.utils.solidityKeccak256(['address', 'uint256'],[one[0], one[1]]));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });
const rootHash2 = merkleTree2.getRoot();//root


//whitelistのmaxmintを返す.
const getWhitelistMaxmintFromFile =(myaddress,mywhitelist) =>{
  for(let i = 0; i < mywhitelist.length; i++){
    const addr = mywhitelist[i][0];
    const maxmint = mywhitelist[i][1];
    if(addr.toLowerCase() == myaddress.toLowerCase()) return maxmint;
  }
  return 0;
}
//マークルツリーのhexProofを得る
const getHexProof = (myaddress,maxmint,mywhitelist,mymerkleTree) => {
  if(maxmint <= 0) return [];
  const leaf = ethers.utils.solidityKeccak256(['address', 'uint256'],[myaddress.toLowerCase(), maxmint]);
  const hexProof = mymerkleTree.getHexProof(leaf);
  return(hexProof);
};


//パラメーター取得
function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

//グローバル変数
const providerOptions = {
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: process.env.REACT_APP_INFURA_KEY // required
    }
  }
};
const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions
});
let provider;
let signer;
let contract;

//メイン
const Main = (props) => {//propsにパラメーターわたってくる
  //検証用の認証。本番では削除する★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
  const navigate = useNavigate();
  if(getParam('a') != "Ey7UgL1V") {
    window.location.href = "https://google.com";
  };

  const [price,setPrice] = useState(ethers.utils.parseEther("0"));//priceの変数とset関数と初期化.BigNumber
  const [isConnected,setConnected] = useState(false);//isConnectedの変数とset関数と初期化
  const [totalSupply,setTotalSupply] = useState(0);//totalSupplyの変数とset関数と初期化
  const [maxSupply,setMaxSupply] = useState(0);//maxSupplyの変数とset関数と初期化
  const [nftPerAddressLimit,setNftPerAddressLimit] = useState(0);//nftPerAddressLimitの変数とset関数と初期化
  const [onlyWhitelisted,setOnlyWhitelisted] = useState(false);//onlyWhitelistedの変数とset関数と初期化
  const [onlyBurnAndMint,setOnlyBurnAndMint] = useState(false);//onlyBurnAndMintの変数とset関数と初期化
  const [onlyHoldAndMint,setOnlyHoldAndMint] = useState(false);//onlyHoldAndMintの変数とset関数と初期化
  const [onlyCheckUsed,setOnlyCheckUsed] = useState(false);//usedのチェックページの変数とset関数と初期化
  const [amount,setAmount] = useState(1);//amountの変数とset関数と初期化.mint数。
  const [canIMint,setCanIMint] = useState(false);//mint可能かの変数とset関数と初期化.
  const [isPaused,setIsPaused] = useState(true);//停止の変数とset関数と初期化.
  const [myAddress,setMyAddress] = useState("");//wallet addressの変数とset関数と初期化.
  const [hasWL,setHasWl] = useState(false);//wlを持ってる変数とset関数と初期化.
  const [hasWL1,setHasWl1] = useState(false);//wl1を持ってる変数とset関数と初期化.
  const [hasWL2,setHasWl2] = useState(false);//wl2を持ってる変数とset関数と初期化.
  const [instance, setInstance] = useState();//web3modalのinstance
  const [isLoading, setIsLoading] = useState(false);//ぐるぐる
  const [isApproved, setIsApproved] = useState(false);//approvedしたか
  const [owndNFTs, setOwndNFTs] = useState([]);//NFTの所有情報
  const [owndNFTsNum, setOwndNFTsNUm] = useState(0);//NFTの所有数
  const [otherContractCount, setOtherContractCount] = useState(0);//NFTの必要数.Burn,Holdで使う
  const [selectedOwndNFTsNum, setSelectedOwndNFTsNum] = useState(0);//NFTの選択数
  const [owndNFTsForce, setOwndNFTsForce] = useState([]);//NFT一覧の強制一覧。
  const [checkInputText, setCheckInputText] = useState([]);//キー入力文字


  //処理
  const connect = async() => {
    try{
      const instance = await web3Modal.connect();
      setInstance(instance);
      provider = new ethers.providers.Web3Provider(instance);
      signer = provider.getSigner();
      contract = new ethers.Contract(contractAddress, abi, provider);
    } catch{

    }
  };
  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    signer=false;
  };
  //再接続
  React.useEffect(() => {
    if (web3Modal.cachedProvider) connect();
  }, []);
  //web3modalの状態更新
  React.useEffect(() => {
    if (instance?.on) {
      const handleDisconnect = () => {
        console.log("disconnect!");
        disconnect();
      };
      const handleChainChanged = async(checkChainId) => {
        const chainId = await getChainId();
        if(chainId != checkChainId){
          alert("Switch to Ethereum mainnet!");
          disconnect();
        }
      };

      instance.on("disconnect", handleDisconnect);
      instance.on("chainChanged", handleChainChanged);


      return () => {
        if (instance.removeListener) {
          instance.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [instance]);

  //NFTの情報取得.非同期。通信が長いとwalletを切り替えても実行しっぱなしになる。
  React.useEffect(() => {
    let isCleanup = false;//cleanup用
    const f = async () => {//await 使うときはこう書く
      try{
        const address = await getAddress();
        const otherContract = await contract.otherContract();
        const holdContract = new ethers.Contract(otherContract, abi, provider);
        let owns = [];
        if(onlyBurnAndMint || onlyHoldAndMint){
          owns = await holdContract.walletOfOwner(address);
        }else{
          owns = owndNFTsForce;//強制一覧
        }
        setOwndNFTsNUm(owns.length);//所有数
        //nftの一覧を作成
        let l = [];
        //空の情報をセット
        for(let i=0;i < owns.length; i++){
          const j ={
            "isLoading":true
          };
          l.push(j);
        }
        setOwndNFTs(l);

        //通信して徐々にデータをセット
        for(let i=0;i < owns.length; i++){
          if(isCleanup) {//cleanupだったら処理しない
            setOwndNFTs([]);//空にする
            return;
          }
          const tokenid = owns[i];
          if(isNaN(tokenid)) continue;//数値じゃないときはスキップ
          let isUsed = await contract.getOtherTokenidUsed(tokenid);
          if(onlyBurnAndMint)isUsed=false;//BMのときは未使用
          let image;
          let isBurned=false;
          try{
            const meta = await holdContract.tokenURI(tokenid);
            const url = ipfsGateway +"ipfs/"+ meta.replace("ipfs://","");
            const res = await axios.get(url);
            image = ipfsGateway +"ipfs/"+res.data.image.replace("ipfs://","");  
          }catch(e){
            image = "/noimage.png";
            isBurned=true;
            setOwndNFTs(l);//途中で表示
          }
          const j ={
            "tokenid":tokenid,
            "isUsed":isUsed,
            "image":image,
            "isBurned":isBurned,
            "isLoading":false
          };
          l[i]=j;
          setOwndNFTs(l);//途中で表示
        }
      }catch{
      }
    };
    f();
    return () => { 
      isCleanup = true ;
    };

  }, [onlyBurnAndMint,onlyHoldAndMint,myAddress,owndNFTsForce,onlyCheckUsed]);

  //address取得
  const getAddress = async() => { try {
    let address = await signer.getAddress();
    address = address.toLowerCase();//小文字に統一する
    return address;
  } catch { return false; }};
  //chainID取得
  const getChainId = async() => {try {
    return await signer.getChainId() 
  } catch { return false; }};
  //表示の更新
  const updateInfo = async() => {
    //接続チェック
    const address = await getAddress();
    const chainId = await getChainId();
    const isOK = (chainId === checkChainId && address ? true : false);
    if(!isOK){
      setConnected(false);
      setCanIMint(false);
      //console.log("Check Wallet Network.exit.");
      return;
    }
    if(address) setConnected(true);
    else setConnected(false);
    setMyAddress(address);//myAddressセット
    setTotalSupply((await contract.totalSupply()).toString());//totalSupplyをセット
    //setTotalSupply((await contract.maxSupply()).toString());//Sold outのテスト用。上をコメントする
    setMaxSupply((await contract.maxSupply()).toString());//maxSupplyをセット.最大のsupply数
    //Sale状況確認
    const isWlEnabled = await contract.isWlEnabled();
    const isPsEnabled = await contract.isPsEnabled();
    const isBmEnabled = await contract.isBmEnabled();
    const isHmEnabled = await contract.isHmEnabled();
    const otherContract = await contract.otherContract();
    //Saleの状態
    let maxMintNum = 0;
    if(props.mesage == "check"){//checkUserdページの時./checkでこうなる
      setOnlyWhitelisted(false);//onlyWhitelistedをセット.
      setOnlyBurnAndMint(false);//Burn&Mintのときにtrue
      setOnlyHoldAndMint(false);//Hold&Mintのときにtrue
      setOnlyCheckUsed(true);//CheckUsedのときにtrue

    }else if(isWlEnabled && !isPsEnabled){//WL Sale
      setOnlyWhitelisted(true);//onlyWhitelistedをセット.WhilteLIstSaleのときにtrue,PublicSaleではfalse
      setOnlyBurnAndMint(false);//Burn&Mintのときにtrue
      setOnlyHoldAndMint(false);//Hold&Mintのときにtrue
      setOnlyCheckUsed(false);//CheckUsedのときにtrue
      //wlチェック
      const maxmintf = getWhitelistMaxmintFromFile(address,whitelist);
      const maxmintf1 = getWhitelistMaxmintFromFile(address,whitelist1);
      const maxmintf2 = getWhitelistMaxmintFromFile(address,whitelist2);
      const hexProof = getHexProof(address,maxmintf,whitelist,merkleTree);
      const hexProof1 = getHexProof(address,maxmintf1,whitelist1,merkleTree1);
      const hexProof2 = getHexProof(address,maxmintf2,whitelist2,merkleTree2);
      setPrice(await contract.getWhitelistedPrice(address,maxmintf+maxmintf1+maxmintf2,hexProof,hexProof1,hexProof2));//WL金額をセット
      const ret = await contract.isWhitelisted(address,maxmintf+maxmintf1+maxmintf2,hexProof,hexProof1,hexProof2);
      setCanIMint(ret);//mintできるかどうか
      maxMintNum = await contract.getWhitelistedMaxMints(address,maxmintf+maxmintf1+maxmintf2,hexProof,hexProof1,hexProof2);
      setNftPerAddressLimit(maxMintNum.toString());//nftPerAddressLimitをセット.このaddressの最大mint数。
      const ret0 = await contract.hasWhitelistedOneWL(address,maxmintf,hexProof);
      setHasWl(ret0);
      const ret1 = await contract.hasWhitelistedOneWL1(address,maxmintf1,hexProof1);
      setHasWl1(ret1);
      const ret2 = await contract.hasWhitelistedOneWL2(address,maxmintf2,hexProof2);
      setHasWl2(ret2);
      setIsPaused(false);//Saleしてる
    }else if(isPsEnabled){//Public Sale
      setPrice(await contract.psMintPrice());//PS金額をセット
      setOnlyWhitelisted(false);//onlyWhitelistedをセット.WhilteLIstSaleのときにtrue,PublicSaleではfalse
      setOnlyBurnAndMint(false);//Burn&Mintのときにtrue
      setOnlyHoldAndMint(false);//Hold&Mintのときにtrue
      setOnlyCheckUsed(false);//CheckUsedのときにtrue
      maxMintNum = await contract.maxMintsPerPS();
      setNftPerAddressLimit(maxMintNum.toString());//nftPerAddressLimitをセット.一人当たりの最大mint数。
      setCanIMint(true);//mintできる
      setIsPaused(false);//Saleしてる
    }else if(isBmEnabled && otherContract != ""){//Burn&Mint Sale
      const holdContract = new ethers.Contract(otherContract, abi, provider);
      const approved = await holdContract.isApprovedForAll(address,contractAddress);
      setIsApproved(approved);
      
      const count = await contract.otherContractCount();
      setOtherContractCount(count.toString());//必要数

      setPrice(await contract.bmMintPrice());//金額をセット
      setOnlyWhitelisted(false);//onlyWhitelistedをセット.WhilteLIstSaleのときにtrue
      setOnlyBurnAndMint(true);//Burn&Mintのときにtrue
      setOnlyHoldAndMint(false);//Hold&Mintのときにtrue
      setOnlyCheckUsed(false);//CheckUsedのときにtrue
      maxMintNum = await contract.maxMintsPerBM();
      setNftPerAddressLimit(maxMintNum.toString());//nftPerAddressLimitをセット.一人当たりの最大mint数。
      setCanIMint(true);//mintできる
      setIsPaused(false);//Saleしてる
      //強制的にRevokeするときにコメントアウト.RinkebyのRevokeが不明
      //const tx = await burnContract.connect(signer).setApprovalForAll(contractAddress,false);
      //await tx.wait();
    }else if(isHmEnabled && otherContract != ""){//Hold&Mint Sale
      const count = await contract.otherContractCount();
      setOtherContractCount(count.toString());//必要数
      const num =  parseInt(selectedOwndNFTsNum/count);//mint数計算
      setAmount(num);


      setPrice(await contract.hmMintPrice());//金額をセット
      setOnlyWhitelisted(false);//onlyWhitelistedをセット.WhilteLIstSaleのときにtrue
      setOnlyBurnAndMint(false);//Burn&Mintのときにtrue
      setOnlyHoldAndMint(true);//Hold&Mintのときにtrue
      setOnlyCheckUsed(false);//CheckUsedのときにtrue

      maxMintNum = await contract.maxMintsPerHM();
      setNftPerAddressLimit(maxMintNum.toString());//nftPerAddressLimitをセット.一人当たりの最大mint数。
      setCanIMint(true);//mintできる
      setIsPaused(false);//Saleしてる
    }else{
      setIsPaused(true);//Saleしていない
      setOnlyWhitelisted(false);//onlyWhitelistedをセット.WhilteLIstSaleのときにtrue,PublicSaleではfalse
      setOnlyBurnAndMint(false);//Burn&Mintのときにtrue
      setOnlyHoldAndMint(false);//Hold&Mintのときにtrue
      setOnlyCheckUsed(false);//CheckUsedのときにtrue

      //wlチェック
      const maxmintf = getWhitelistMaxmintFromFile(address,whitelist);
      const maxmintf1 = getWhitelistMaxmintFromFile(address,whitelist1);
      const maxmintf2 = getWhitelistMaxmintFromFile(address,whitelist2);
      const hexProof = getHexProof(address,maxmintf,whitelist,merkleTree);
      const hexProof1 = getHexProof(address,maxmintf1,whitelist1,merkleTree1);
      const hexProof2 = getHexProof(address,maxmintf2,whitelist2,merkleTree2);
      setPrice(await contract.getWhitelistedPrice(address,maxmintf+maxmintf1+maxmintf2,hexProof,hexProof1,hexProof2));//WL金額をセット
      maxMintNum = await contract.getWhitelistedMaxMints(address,maxmintf+maxmintf1+maxmintf2,hexProof,hexProof1,hexProof2);
      setNftPerAddressLimit(maxMintNum.toString());//nftPerAddressLimitをセット.このaddressの最大mint数。
      const ret = await contract.isWhitelisted(address,maxmintf+maxmintf1+maxmintf2,hexProof,hexProof1,hexProof2);
      setCanIMint(ret);//mintできるかどうか
      const ret0 = await contract.hasWhitelistedOneWL(address,maxmintf,hexProof);
      setHasWl(ret0);
      const ret1 = await contract.hasWhitelistedOneWL1(address,maxmintf1,hexProof1);
      setHasWl1(ret1);
      const ret2 = await contract.hasWhitelistedOneWL2(address,maxmintf2,hexProof2);
      setHasWl2(ret2);

    }
  };
  //Reactのインターバル　
  React.useEffect(function() {
    const intervalId = setInterval(function() {
      updateInfo();
    }, 2000);
    return function(){clearInterval(intervalId)};
  }, [price,isConnected,totalSupply,maxSupply,nftPerAddressLimit,onlyWhitelisted,onlyBurnAndMint,onlyHoldAndMint,amount,canIMint,isPaused,myAddress,hasWL,hasWL1,hasWL2,isApproved,owndNFTs,otherContractCount,owndNFTsNum]);

  window.onload = async() => {
    await updateInfo();
  };

  //Clickの処理.NFTをmint
  const buttonConnect = async() => {
    await connect();
    const address = await getAddress();
    const chainId = await getChainId();
    const isOK = (chainId === checkChainId && address ? true : false);
    if(!isOK){
      setConnected(false);
      setCanIMint(false);
      if(chainId && chainId != checkChainId)alert("Switch to Ethereum mainnet!");
      return;
    }else setConnected(true);
    await updateInfo();

  };

  //Clickの処理.Minus
  const buttonMinus = async() => {
    let amountVal = amount;
    --amountVal;
    if(amountVal < 1) amountVal = 1;
    setAmount(amountVal);
  };
  //Clickの処理.Plus
  const buttonPlus = async() => {
    let amountVal = amount;
    ++amountVal;
    if(amountVal > nftPerAddressLimit) amountVal = nftPerAddressLimit;
    setAmount(amountVal);
  };
  
  //Clickの処理.NFTをmint
  const buttonMint = async() => {
    try{
      const address = await getAddress();
      //署名を付けてコントラクトのmintを実行します。
      const maxmintf = getWhitelistMaxmintFromFile(address,whitelist);
      const maxmintf1 = getWhitelistMaxmintFromFile(address,whitelist1);
      const maxmintf2 = getWhitelistMaxmintFromFile(address,whitelist2);
      const hexProof = getHexProof(address,maxmintf,whitelist,merkleTree);
      const hexProof1 = getHexProof(address,maxmintf1,whitelist1,merkleTree1);
      const hexProof2 = getHexProof(address,maxmintf2,whitelist2,merkleTree2);
      let tx;
      if(!isPaused && onlyWhitelisted){//WhiteList
        const valuePrice = ethers.BigNumber.from(price).mul(amount);
        tx = await contract.connect(signer).whitelistMint(amount,maxmintf+maxmintf1+maxmintf2,hexProof,hexProof1,hexProof2,{value:valuePrice});//metamaskの署名を要求する
        setIsLoading(true);
        await tx.wait();
        setIsLoading(false);
      }else if(!isPaused && !onlyWhitelisted && !onlyBurnAndMint && !onlyHoldAndMint){//Public
        const valuePrice = ethers.BigNumber.from(price).mul(amount);
        tx = await contract.connect(signer).publicMint(amount,{value:valuePrice});//metamaskの署名を要求する
        setIsLoading(true);
        await tx.wait();
        setIsLoading(false);
      }else if(!isPaused && onlyBurnAndMint){//Burn&Mint
        if(selectedOwndNFTsNum != otherContractCount){alert("Incorrect number selected.");return;}
        const valuePrice = ethers.BigNumber.from(price).mul(amount);
        let tokenids=[];
        for(let i = 0; i < owndNFTs.length;i++){
          if(owndNFTs[i].checked) tokenids.push(owndNFTs[i].tokenid);
        }
        tx = await contract.connect(signer).burnAndMint(amount,tokenids,{value:valuePrice});//metamaskの署名を要求する
        setIsLoading(true);
        await tx.wait();
        setIsLoading(false);
        //チェックしたNFTは画面上で削除する。
        for(let i = 0; i < owndNFTs.length;i++){
          if(owndNFTs[i].checked){
            delete owndNFTs[i]; 
          }
        }
        setOwndNFTs(owndNFTs);        
        setSelectedOwndNFTsNum(0);//選択０
        const otherContract = await contract.otherContract();
        const holdContract = new ethers.Contract(otherContract, abi, provider);
        const owns = await holdContract.walletOfOwner(address);
        setOwndNFTsNUm(owns.length);//所有数
      }else if(!isPaused && onlyHoldAndMint){//Hold&Mint
        if(selectedOwndNFTsNum != otherContractCount){alert("Incorrect number selected.");return;}
        const valuePrice = ethers.BigNumber.from(price).mul(amount);
        let tokenids=[];
        for(let i = 0; i < owndNFTs.length;i++){
          if(owndNFTs[i].checked) tokenids.push(owndNFTs[i].tokenid);
        }
        tx = await contract.connect(signer).holdAndMint(amount,tokenids,{value:valuePrice});//metamaskの署名を要求する
        setIsLoading(true);
        await tx.wait();
        setIsLoading(false);
        //チェックしたNFTはisUsedにして未選択に画面上はする。
        for(let i = 0; i < owndNFTs.length;i++){
          if(owndNFTs[i].checked){
            owndNFTs[i].checked = false;
            owndNFTs[i].isUsed = true;
          }
        }
        setOwndNFTs(owndNFTs);
        setSelectedOwndNFTsNum(0);//選択０
      }
    }catch(e){
      setIsLoading(false);
      handleErrTx(e)
    };
  };
  //Clickの処理.burn NFTをapprove
  const buttonApprove = async() => {
    try{
      const address = await getAddress();
      const otherContract = await contract.otherContract();
      const burnContract = new ethers.Contract(otherContract, abi, provider);
      const tx = await burnContract.connect(signer).setApprovalForAll(contractAddress,true);
      setIsLoading(true);
      await tx.wait();
      setIsLoading(false);
      const approved = await burnContract.isApprovedForAll(address,contractAddress);
      setIsApproved(approved);
    }catch(e){
      setIsLoading(false);
      handleErrTx(e)
    };
  };
  //checkbox処理
  const handleCheckboxChange = async(e) => {
    try{
      owndNFTs[e.target.id].checked = e.target.checked;//check状態を記録
      setOwndNFTs(owndNFTs);
      let count=0;
      for(let i = 0; i < owndNFTs.length;i++){
        if(owndNFTs[i].checked) count++;
      }
      setSelectedOwndNFTsNum(count);//選択数セット
      const num =  parseInt(count/otherContractCount);//mint数計算
      setAmount(num);
  
    }catch(e){
      handleErrTx(e)
    };
  };
  //check usedボタン
  const inputCheckChanged = async(e) => {
    setCheckInputText(e.target.value);//キー入力が拾えないときがある
    buttonCheckUsed();
  };
  const buttonCheckUsed = async() => {
    try{
      let list=[];
      try{
        list =checkInputText.split(" ");//space区切り
        let list1 = [];
        for(let i=0;i<list.length;i++){
          if(list[i] == "") continue;//空はスキップ
          if(isNaN(list[i])) continue;//数値じゃないときはスキップ
          list1.push(ethers.BigNumber.from(list[i]));
        }
        setOwndNFTsForce(list1);
      }catch(e){
      }
    }catch(e){
      handleErrTx(e)
    };
  };

  
  //エラー処理.tx系
  const handleErrTx = async(e) => {
    let message = e.message;
    if(message.indexOf("insufficient funds for") >= 0) message="You have not enough ETH.";
    if(message.indexOf("Over max mints per wallet") >= 0) message="Over max mints per wallet.";
    if(message.indexOf("You have no whitelistMint left") >= 0) message="You have no whitelistMint left.";
    if(message.indexOf("You have no whitelistMint1 left") >= 0) message="You have no whitelistMint1 left.";
    if(message.indexOf("You have no whitelistMint2 left") >= 0) message="You have no whitelistMint2 left.";
    if(message.indexOf("You have no publicMint left") >= 0) message="You have no publicMint left.";
    if(message.indexOf("You have no Burn&MintSale left") >= 0) message="You have no Burn&MintSale left.";
    if(message.indexOf("You have no Hold&MintSale left") >= 0) message="You have no Hold&MintSale left.";
    if(message.indexOf("No more NFT") >= 0) message="No more NFTs.";
    if(message.indexOf("user rejected") >= 0) message="You rejected tx.";
    if(message.indexOf("transaction was replaced") >= 0) return;//スピードアップなので無視
    alert("Error : "+message);
  };
 
 
  //ここはJSXの書き方
  return (
    <>
    {!onlyCheckUsed && <>
      {isConnected && isPaused && <>
        <p className="ttl">Not Sales Now.</p>
        {<p className="l-txt">{canIMint ? "You are WhiteListed.":"You are not WhiteListed."}</p>}
        {canIMint && <>
          <p className="l-txt">max mint: {nftPerAddressLimit}<br/></p>
          <p className="l-txt">price: {ethers.utils.formatEther(price)} ETH<br/></p>
        </>}
        {<p className="address">Your Wallet address: {myAddress}</p>}
      </>}

      {isConnected && !isPaused && <>
        {<p className="ttl">{onlyWhitelisted ? "Now White List Sale!": onlyBurnAndMint ? "Now Burn&Mint Sale!": onlyHoldAndMint ?  "Now Hold&Mint Sale!" : "Now Public Sale!" }</p>}
        {<p className="total-supply">{totalSupply}/{maxSupply}</p>}
        {parseInt(totalSupply) >= parseInt(maxSupply) &&  <p className="total-supply">Sold Out!</p>}
        {(canIMint && (parseInt(totalSupply) < parseInt(maxSupply))) && 
          <div className="mint-cnt">
          <div className="cnt-block">
          <button id="minus" onClick={buttonMinus}>-</button>
          <p className="num">{amount}</p>
          <button id="plus" onClick={buttonPlus}>+</button>
          </div>
          <p className="s-txt">max mint: {nftPerAddressLimit}</p>
          <p className="s-txt">price: {ethers.utils.formatEther(price)} ETH</p>

          {(!onlyBurnAndMint || isApproved) && <p className="btn-typ03"><button id="mint" onClick={buttonMint}>
          {isLoading && <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="20" visible={true}/>}
            NFT mint</button>
          </p>}
        </div>}
        {<p className="address">Your Wallet address: {myAddress}</p>}
      </>}
    </>}  
    {!isConnected && <p className="btn-typ01"><button id="connect" onClick={buttonConnect}>Connect Wallet</button></p>}
    {isConnected && <p className="btn-typ01-a"><button id="disconnect" onClick={disconnect}>Disconnect Wallet</button></p>}

  </>
  );
}
export default Main;